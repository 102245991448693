import Cookies from "js-cookie";
import { useEffect } from "react";
import { useRouter } from "next-nprogress-bar";
import { useParams, usePathname } from "next/navigation";
import { getDelocalizedPathname, i18nConfig } from "@/i18n/settings";
import {
  constants,
  notToShowAfterLogin,
  notToShowBeforeLogin,
  routes,
} from "../utils";

//Safety Cusion in case middleware fails
export const useRedirectToHome = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { locale } = useParams();

  useEffect(() => {
    const cookieLocale =
      Cookies.get(constants.cookies.locale) || i18nConfig.defaultLocale;
    const isAuthenticated = Cookies.get(constants.cookies.customerToken);
    const delocalizedPathname = getDelocalizedPathname(pathname);

    if (locale !== cookieLocale) {
      Cookies.set(constants.cookies.locale, locale, {
        sameSite: "strict",
        expires: 365,
        secure: true,
      });
    }

    if (isAuthenticated) {
      if (notToShowAfterLogin.includes(delocalizedPathname)) {
        router.replace("/");
      }
    } else if (notToShowBeforeLogin.includes(delocalizedPathname)) {
      router.replace(routes.signin);
    }
  }, [router, pathname, locale]);
};
