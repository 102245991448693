import { useEffect } from "react";
import { useAppDispatch } from "@/lib/store";
import { useRouter } from "next-nprogress-bar";
import { customerAxios } from "@/lib/utils/axios";
import { handleClearBrowserStorage, handleLogout } from "@/lib/services/auth";
import { handleGraphqlResponse, messages, routes } from "@/lib/utils";

const logoutWhen = [
  "The current customer isn't authorized.",
  "The current user cannot perform operations on cart",
];

//Just to clear localStorage and Cookies
const clearBrowserStorageWhen = ["The current customer has already logout."];

const useAxiosInterceptor = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    const loadInterceptor = async () => {
      customerAxios.interceptors.response.use((axiosRes) => {
        const { data: apiRes } = axiosRes;
        try {
          handleGraphqlResponse(apiRes);
        } catch (error) {
          const needLoggingOut = logoutWhen.some((msg) =>
            error.message.includes(msg),
          );
          const needclearBrowserStorage = clearBrowserStorageWhen.some((msg) =>
            error.message.includes(msg),
          );
          if (needLoggingOut) {
            apiRes.errors[0].message = messages.error.sessionExpired;
            handleLogout({
              dispatch,
              onSuccess() {
                router.push(routes.signin);
              },
            });
          }
          if (needclearBrowserStorage) {
            apiRes.errors[0].message = messages.error.sessionExpired;
            handleClearBrowserStorage({
              dispatch,
              onSuccess() {
                router.push(routes.signin);
              },
            });
          }
        }
        return axiosRes;
      });
    };
    loadInterceptor();
  }, [dispatch, router]);
};

export default useAxiosInterceptor;
