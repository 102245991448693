import Button from "../../Button/button";
import { constants, getPrice } from "@/lib/utils";
import CartActions from "@/components/common/CartActions";
import OptimizedImage from "@/components/common/OptimizedImage";
import InfoIcon from "@/assets/icon/indo-tooltip-icon.svg";

const ProductItem = ({
  cartItem,
  onRemove,
  handleQuantityInput,
  productState,
  handleEnterOnInput,
  handleMinus,
  handlePlus,
  fromMinitrolly,
}) => {
  const discount =
    cartItem?.product?.price_range?.minimum_price?.discount?.percent_off;

  const regularPrice =
    cartItem?.product?.price_range?.minimum_price?.regular_price;
  const finalPrice =
    +(cartItem?.product?.price_range?.minimum_price?.final_price?.value *
    cartItem?.quantity).toFixed(2);
  const rowTotalIncTax = cartItem?.prices?.row_total_including_tax?.value;
  const oldPrice = (regularPrice?.value ?? 0) * (cartItem?.quantity ?? 0);

  const surchargeItem = cartItem?.product?.extraVariable?.fitmentInfo?.find(
    (item) => item.label === "Surcharge",
  );
  return (
    <div className="border-b border-b-primary-black-50 mb-3 pb-3 last:border-b-0">
      <div className="flex">
        <div className="imageBox">
          <OptimizedImage
            width={120}
            height={120}
            type={constants.image_types.product}
            src={cartItem?.product?.thumbnail?.url}
            alt={cartItem?.product?.thumbnail?.label || "Product"}
            className="border border-primary-black-50 rounded-[6px] h-[120px] w-[120px] p-[15px] object-contain"
          />
        </div>
        <div className="productContent">
          <Button
            theme="iconOnly"
            icon="dustbin"
            className="right-0 top-0 absolute"
            onClick={() => onRemove(cartItem)}
          ></Button>
          <p className="xs:text-body-text-2-sb text-body-text-3-sb mb-2">
            {cartItem?.product?.name || "Product"}
          </p>
          <div>
            <div className="text-body-text-2-b flex items-center">
              <span className="text-primary-black-400 me-1 text-subtitle-4">
                Price
              </span>
              <div>
                <span
                  className={`productPrice ${
                    finalPrice > rowTotalIncTax ||
                    (discount > 0 && finalPrice <= rowTotalIncTax)
                      ? "red"
                      : ""
                  }`}
                >
                  {getPrice(
                    rowTotalIncTax,
                    cartItem?.prices?.row_total_including_tax?.currency,
                  )}
                </span>
                {finalPrice > rowTotalIncTax && (
                  <span className="oldPrice ms-[6px]">
                    {getPrice(
                      finalPrice,
                      cartItem?.prices?.row_total_including_tax?.currency,
                    )}
                  </span>
                )}
                {discount > 0 && finalPrice <= rowTotalIncTax && (
                  <span className="oldPrice">
                    {getPrice(oldPrice, regularPrice?.currency)}
                  </span>
                )}
              </div>
            </div>

            <div className="cart-action-box ">
              <CartActions
                cartItem={cartItem}
                handleQuantityInput={handleQuantityInput}
                productState={productState}
                handleEnterOnInput={handleEnterOnInput}
                handleMinus={handleMinus}
                handlePlus={handlePlus}
                showRemoveModal={false}
                fromMinitrolly={fromMinitrolly}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="trolly-offers-group">
        {cartItem?.product?.sold_in_pairs == 1 && (
          <div className="bg-tertiary-yellow-600 w-12/12  p-[6px] rounded-lg mt-3">
            <div className="flex gap-2">
              <div className="w-5 block h-auto m-auto">
                <InfoIcon />
              </div>
              <p className="w-full max-w-[clac(100%-28px)] text-minor-text-1-mb">
                Please note that this product can only be sold in units of 2
              </p>
            </div>
          </div>
        )}
        {surchargeItem && (
          <div className="bg-tertiary-yellow-600 w-12/12 p-[6px] rounded-lg mt-3">
            <div className="flex gap-2">
              <div className="w-5 block h-auto m-auto">
                <InfoIcon />
              </div>
              <p className="w-full  text-minor-text-1-mb">
                `£{surchargeItem?.value} trade-in for your old product. No
                vouchers / discounts apply`
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductItem;
