import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import useAppInitialization from "./useAppInitialization";
import Loader from "@/components/common/Loader";
import MiniTrolley from "@/components/common/shared/MiniTrolley";
import { createContext, useContext, useMemo } from "react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";

const AppInitializationContext = createContext();

export const useAppContext = () => {
  return useContext(AppInitializationContext);
};

export function AppInitialization({ children }) {
  const {
    data: { routeLoading, loading, miniTrolley, appContextData },
    methods: { dispatch },
  } = useAppInitialization();

  const ToastContainerMemo = useMemo(
    () => (
      <ToastContainer
        position="top-right"
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        style={{
          zIndex: 99999999999,
        }}
        className="custom-toastify"
      />
    ),
    [],
  );
  
  return (
    <AppInitializationContext.Provider value={appContextData}>
        <ProgressBar
          options={{
            showSpinner: false,
          }}
          style={`#nprogress { display: none; }`}
        />
        {(routeLoading || loading > 0) && <Loader />}
        {ToastContainerMemo}
        <MiniTrolley isVisible={miniTrolley} dispatch={dispatch} />
        {children}
    </AppInitializationContext.Provider>
  );
}
