"use client";

import { useEffect } from "react";
import Button from "../Button/button";

const Modal = ({
  open,
  title,
  mblOpen,
  onSubmit,
  onCancel,
  cancelBtn,
  confirmBtn,
  storeBtn,
  description,
  className = "",
  confirmBtnClass = "",
  isFitment = false,
  children,
}) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add("body-fixed");
    } else {
      document.body.classList.remove("body-fixed");
    }
  }, [open]);

  useEffect(() => {
    if (mblOpen) {
      document.body.classList.add("mbl-body-fixed");
    } else {
      document.body.classList.remove("mbl-body-fixed");
    }
  }, [mblOpen]);

  return (
    <div
      className={`modal ${isFitment ? "fitment-modal" : ""} ${
        open || mblOpen ? "show" : "hide"
      } ${className}`}
    >
      <div className="box">
        <div className="boxHeader">
          {title && (
            <h4
              className={`heading-24 text-primary-black font-bold ${
                isFitment ? "modal-title" : ""
              }`}
            >
              {title}
            </h4>
          )}
          <Button
            theme="iconOnly"
            icon="crossBlack"
            onClick={onCancel}
            className="my-auto ms-auto"
          />
        </div>
        <div className="boxContent scrollbar">
          {description && <p>{description}</p>}
          {children}
        </div>
        {(confirmBtn || cancelBtn) && (
          <div className="boxFooter">
            <div className="flex xs:gap-24 gap-12">
              {confirmBtn && (
                <Button
                  theme="blue-btn"
                  onClick={onSubmit}
                  className={confirmBtnClass}
                >
                  {confirmBtn}
                </Button>
              )}
              {cancelBtn && (
                <Button theme="blue-bordered-btn" onClick={onCancel}>
                  {cancelBtn}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Modal;
