import { useAppContext } from "@/providers/AppInitialization";
import {
  addToTrolley,
  applyCouponToCart,
  removeCouponFromCart,
  removeItemFromCart,
  removeOutOfStockProductsFromCart,
  updateItemQuantity,
} from "../services/cart";
import { useAppDispatch, useAppSelector } from "../store";
import Cookies from "js-cookie";
import {
  addOrUpdateAppliedCoupons,
  addOrUpdateCartPrice,
  addOrUpdateProducts,
  getAppliedCoupons,
  getCartCounts,
  getCartId,
  getCartPrice,
  getCartProducts,
  removeCartProduct,
  updateTotalCount,
  updateUniqCount,
  updateIsHomedeliveryAvailable,
  getIsHomeDeliveryAvailable,
  getIsCnCAvailable,
  removeCartProducts,
  getCartAlerts,
  fetchClickAndCollectStock,
  getBranchId,
  fetchCartForLoggedInUser,
  fetchCartForGuestUser,
  getIsCncSelected,
} from "../store/reducers/cart";
import { endCommonLoading, startCommonLoading } from "../store/reducers/common";
import {
  constants,
  formatProductGA4,
  formatRemovedProductGA4,
  getProductsMap,
  handleGraphqlResponse,
  handleThunkResponse,
  handleToastError,
  handleToastProduct,
  handleToastSuccess,
  isValidInput,
  messages,
  mutateProductInfo,
  routes,
} from "../utils";
import { produce } from "immer";
import { useState } from "react";
import Modal from "@/components/common/shared/Modal";
import { usePathname } from "next/navigation";
import { add_to_cart, removeFromCartAnalytics } from "../gtm";

const useTrolleyUtility = () => {
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const { productsState, setProductsState } = useAppContext();

  const cartProducts = useAppSelector(getCartProducts);
  const { uniqCount, totalCount } = useAppSelector(getCartCounts);
  const alerts = useAppSelector(getCartAlerts);
  const cartPrice = useAppSelector(getCartPrice);
  const cartId = useAppSelector(getCartId);
  const isHomeDeleiveryAvailable = useAppSelector(getIsHomeDeliveryAvailable);
  const isCnCAvailable = useAppSelector(getIsCnCAvailable);
  const appliedCoupons = useAppSelector(getAppliedCoupons);
  const selectedBranchId = useAppSelector(getBranchId);
  const cncSelected = useAppSelector(getIsCncSelected);

  //For Coupon Codes
  const specialCoupon = Cookies.get("specialCoupon");
  const [coupon, setCoupon] = useState({
    code: "",
    error: "",
  });

  const handleAddToTrolley = async ({ product, onSuccess, addToCartEvent }) => {
    const { sku, name, thumbnail, soldInPairs = 0 } = product;
    const { url } = thumbnail || {};
    const showCTA = pathname !== routes.basket;
    const quantityToAdd = soldInPairs == 1 ? 2 : 1;
    const cartItems = [{ sku, quantity: quantityToAdd }];

    dispatch(startCommonLoading());
    try {
      const customerToken = Cookies.get(constants.cookies.customerToken);
      const cartId = localStorage.getItem(constants.localStorage.cartId);

      if (!cartId) {
        if (!customerToken) {
          await dispatch(fetchCartForGuestUser()).then(handleThunkResponse);
        } else {
          await dispatch(fetchCartForLoggedInUser()).then(handleThunkResponse);
        }
      }

      localStorage.setItem(constants.localStorage.shouldFetchCartDetails, 1);

      const apiData = await addToTrolley({ cartItems });
      const data = handleGraphqlResponse(
        apiData,
        (d) => d?.addProductsToCart?.user_errors,
      );
      const products = data?.addProductsToCart?.cart?.itemsV2?.items;
      const productsMap = getProductsMap(products);
      const _cartPrice = data?.addProductsToCart?.cart?.prices || {};
      const uniq_Count = data?.addProductsToCart?.cart?.itemsV2?.total_count;
      const total_Count = data?.addProductsToCart?.cart?.total_quantity;
      const isHomeDeliveryAvailable =
        data?.addProductsToCart?.cart?.is_home_delivery_available;
      dispatch(addOrUpdateProducts(productsMap));
      dispatch(addOrUpdateCartPrice(_cartPrice));
      dispatch(updateTotalCount(total_Count));
      dispatch(updateUniqCount(uniq_Count));
      dispatch(updateIsHomedeliveryAvailable(isHomeDeliveryAvailable));
      handleToastProduct({ name, image: url, showCTA });
      addToCartEvent?.();
      onSuccess?.();
    } catch (error) {
      console.error("error", error);
      handleToastError(error.message);
    }
    dispatch(endCommonLoading());
  };

  const handleRemoveFromCart = async ({ cartItem, onSuccess }) => {
    const { uid, product } = cartItem;
    const { sku } = product;

    let coupon = [];

    if (Cookies.get("specialCoupon")) {
      coupon.push({ code: Cookies.get("specialCoupon") });
    }

    const mutatedProduct = mutateProductInfo(cartItem);

    // single product removal
    removeFromCartAnalytics({
      total: mutatedProduct?.prices?.row_total_including_tax,
      items: formatProductGA4(
        [
          {
            ...product,
            prices: mutatedProduct?.prices,
            quantity: cartItem?.quantity,
          },
        ],
        coupon,
        true,
      ),
    });

    dispatch(startCommonLoading());
    try {
      const apiData = await removeItemFromCart({ cartId, cartItemId: uid });
      const data = handleGraphqlResponse(apiData);
      const _cartPrice = data?.removeItemFromCart?.cart?.prices;
      const uniq_Count = data?.removeItemFromCart?.cart?.itemsV2?.total_count;
      const total_Count = data?.removeItemFromCart?.cart?.total_quantity;
      const isHomeDeliveryAvailable =
        data?.removeItemFromCart?.cart?.is_home_delivery_available;
      dispatch(addOrUpdateCartPrice(_cartPrice));
      dispatch(updateTotalCount(total_Count));
      dispatch(updateUniqCount(uniq_Count));
      dispatch(removeCartProduct(sku));
      dispatch(updateIsHomedeliveryAvailable(isHomeDeliveryAvailable));
      onSuccess?.();
    } catch (error) {
      handleToastError(error.message);
      setProductsState(cartProducts);
    }
    dispatch(endCommonLoading());
  };

  const handleRemoveOutStockProductsFromCart = async ({
    cartItems,
    onSuccess,
  }) => {
    const productSKUs = Object.keys(cartItems).filter((val) => {
      const item = cartItems[val];
      let isAvailable = true;
      if (cncSelected) {
        isAvailable = item?.cnc?.in_stock;
        isAvailable = !isAvailable || item?.cnc?.stock_qty < item?.quantity;
      } else {
        isAvailable = item?.is_available;
        isAvailable =
          !isAvailable || item?.product?.extraVariable?.qty < item?.quantity;
      }

      return isAvailable;
    });

    const productIds = productSKUs.map((val) => cartItems[val].uid);

    const priceTotal = Object.values(cartItems)
      .filter((el) => productSKUs.includes(el.product.sku))
      .map((el) => el.prices.row_total_including_tax);

    const currency = priceTotal[0].currency;

    const totalValue = priceTotal.reduce((accumulator, current) => {
      return accumulator + current.value;
    }, 0);

    const totalPrice = { value: totalValue, currency: currency };

    const products = Object.values(cartItems)
      .filter((el) => productSKUs.includes(el.product.sku))
      .map((el) => {
        return {
          ...el.product,
          prices: mutateProductInfo(el)?.prices,
          first_brand: el?.product?.extraVariable?.brand_info?.[0],
          quantity: el.quantity,
        };
      });

    let coupon = [];

    if (Cookies.get("specialCoupon")) {
      coupon.push({ code: Cookies.get("specialCoupon") });
    }

    // multiple product removal
    removeFromCartAnalytics({
      total: totalPrice,
      items: formatProductGA4(products, coupon, true),
    });

    dispatch(startCommonLoading());
    try {
      const apiData = await removeOutOfStockProductsFromCart({
        cartItemIds: productIds,
      });
      const data = handleGraphqlResponse(apiData);
      const _cartPrice = data?.removeItemsFromCart?.cart?.prices;
      const uniq_Count = data?.removeItemsFromCart?.cart?.itemsV2?.total_count;
      const total_Count = data?.removeItemsFromCart?.cart?.total_quantity;
      const isHomeDeliveryAvailable =
        data?.removeItemsFromCart?.cart?.is_home_delivery_available;

      dispatch(addOrUpdateCartPrice(_cartPrice));
      dispatch(updateTotalCount(total_Count));
      dispatch(updateUniqCount(uniq_Count));
      dispatch(removeCartProducts(productSKUs));
      dispatch(updateIsHomedeliveryAvailable(isHomeDeliveryAvailable));

      onSuccess?.();
    } catch (error) {
      handleToastError(error.message);
      setProductsState(cartProducts);
    }
    dispatch(endCommonLoading());
  };

  const handleUpdateQuantity = async ({ cartItem, quantity, onSuccess }) => {
    const { uid } = cartItem;
    const cartItems = [{ cart_item_uid: uid, quantity }];
    dispatch(startCommonLoading());
    try {
      const apiData = await updateItemQuantity({ cartItems });
      const data = handleGraphqlResponse(apiData);
      const products = data?.updateCartItems?.cart?.itemsV2?.items;
      const productsMap = getProductsMap(products);
      const _cartPrice = data?.updateCartItems?.cart?.prices;
      const uniq_Count = data?.updateCartItems?.cart?.itemsV2?.total_count;
      const total_Count = data?.updateCartItems?.cart?.total_quantity;
      const isHomeDeliveryAvailable =
        data?.updateCartItems?.cart?.is_home_delivery_available;

      dispatch(addOrUpdateProducts(productsMap));
      dispatch(addOrUpdateCartPrice(_cartPrice));
      dispatch(updateTotalCount(total_Count));
      dispatch(updateUniqCount(uniq_Count));
      dispatch(updateIsHomedeliveryAvailable(isHomeDeliveryAvailable));
      onSuccess?.();
    } catch (error) {
      handleToastError(error.message);
      setProductsState(cartProducts);
    }
    dispatch(endCommonLoading());
  };

  const handleQuantityInput = (e, { cartItem }) => {
    const { product } = cartItem;
    const { sku } = product;

    let value = e.target.value;
    if (!isValidInput(value)) {
      return;
    }
    if (value.length > 10) return;
    if (value) value = parseInt(Math.abs(value));
    setProductsState(
      produce((prevState) => {
        prevState[sku].quantity = value;
      }),
    );
  };

  const handleEnterOnInput = async (
    e,
    { cartItem, onSuccess, showRemoveModal = false },
  ) => {
    e.preventDefault();
    const { product, quantity: oldQuantity } = cartItem;
    const { sku, sold_in_pairs = 0 } = product;
    let quantity = +productsState[sku].quantity;

    // Ensure even quantity if soldInPairs is 1
    if (sold_in_pairs === 1 && quantity % 2 !== 0) {
      quantity += 1;
    }
    if (oldQuantity === quantity) {
      return;
    }
    if (quantity < 1) {
      if (showRemoveModal) {
        onRemove(cartItem);
      } else {
        handleRemoveFromCart({
          cartItem,
          onSuccess: () => {
            handleToastSuccess(messages.success.productRemoved);
          },
        });
      }
      return;
    }

    //check if product removed on enter input
    const mutatedProduct = mutateProductInfo(cartItem);
    if (oldQuantity > quantity) {
      let coupon = [];

      if (Cookies.get("specialCoupon")) {
        coupon.push({ code: Cookies.get("specialCoupon") });
      }

      // single product removal
      removeFromCartAnalytics({
        total: {
          currency: product?.price_range?.minimum_price?.final_price?.currency,
          value:
            mutatedProduct?.prices?.price_including_tax?.value *
            (oldQuantity - quantity),
        },
        items: formatRemovedProductGA4(
          [
            {
              ...product,
              prices: mutatedProduct.prices,
              quantity: oldQuantity - quantity,
            },
          ],
          coupon,
          true,
        ),
      });
    } else if (oldQuantity < quantity) {
      let ga4_payload = {
        currency: product?.price_range?.minimum_price?.final_price?.currency,
        value: Number(
          (
            mutatedProduct?.prices?.price_including_tax?.value *
            (quantity - oldQuantity)
          ).toFixed(2),
        ),
        items: formatRemovedProductGA4(
          [
            {
              ...product,
              prices: mutatedProduct.prices,
              quantity: Math.abs(oldQuantity - quantity),
            },
          ],
          appliedCoupons,
          true,
        ),
      };
      add_to_cart(ga4_payload);
    }
    handleUpdateQuantity({ cartItem, onSuccess, quantity: quantity });
  };

  const handlePlus = async ({ cartItem, onSuccess }) => {
    const { product } = cartItem;
    const { sku, sold_in_pairs = 0 } = product;
    const increment = sold_in_pairs === 1 ? 2 : 1;
    const quantity = +productsState[sku].quantity + increment;
    setProductsState(
      produce((prevState) => {
        prevState[sku].quantity = quantity;
      }),
    );
    const mutatedProduct = mutateProductInfo(cartItem, true);
    let ga4_payload = {
      currency: mutatedProduct?.prices?.price_including_tax?.currency,
      value: mutatedProduct?.prices?.price_including_tax?.value.toFixed(2),
      items: formatRemovedProductGA4(
        [{ ...product, prices: mutatedProduct?.prices, quantity: 1 }],
        appliedCoupons,
        true,
      ),
    };
    add_to_cart(ga4_payload);
    handleUpdateQuantity({ cartItem, quantity, onSuccess });
  };

  const handleMinus = async ({
    cartItem,
    onSuccess,
    showRemoveModal = false,
  }) => {
    const { product } = cartItem;
    const { sku, sold_in_pairs = 0 } = product;
    const decrement = sold_in_pairs === 1 ? 2 : 1;
    const quantity = +productsState[sku].quantity - decrement;
    setProductsState(
      produce((prevState) => {
        prevState[sku].quantity = quantity;
      }),
    );
    // single product removal

    let coupon = [];

    if (Cookies.get("specialCoupon")) {
      coupon.push({ code: Cookies.get("specialCoupon") });
    }

    const mutatedProduct = mutateProductInfo(cartItem, true);

    removeFromCartAnalytics({
      total: mutatedProduct?.prices?.price_including_tax,
      items: formatRemovedProductGA4(
        [{ ...product, prices: mutatedProduct?.prices, quantity: 1 }],
        coupon,
        true,
      ),
    });
    if (quantity < 1) {
      if (showRemoveModal) {
        onRemove(cartItem);
      } else {
        handleRemoveFromCart({
          cartItem,
          onSuccess: () => {
            handleToastSuccess(messages.success.productRemoved);
          },
        });
      }
      return;
    }
    handleUpdateQuantity({ cartItem, quantity, onSuccess });
  };

  //For Remove Modal
  const [itemToRemove, setItemToRemove] = useState(null);
  const [removeModal, setRemoveModal] = useState(false);

  const onRemove = ({ cartItem }) => {
    setItemToRemove(cartItem);
    setRemoveModal(true);
  };

  const onConfirmRemove = () => {
    setRemoveModal(false);
    handleRemoveFromCart({
      cartItem: itemToRemove,
      onSuccess: () => {
        handleToastSuccess(messages.success.productRemoved);
      },
    });
    setItemToRemove(null);
  };

  const onCancelRemove = () => {
    setRemoveModal(false);
    setItemToRemove(null);
    //Revert the quantity back to previous
    setProductsState(cartProducts);
  };

  //For Coupon Codes
  const handleCouponCodeChange = (e) => {
    const code = e.target.value;
    if (!isValidInput(code)) {
      return;
    }
    setCoupon({ code, error: "" });
  };

  const handleApplyCouponCode = async ({ onSuccess } = {}) => {
    if (!coupon.code && !specialCoupon) {
      setCoupon(
        produce((prevState) => {
          prevState.error = "Please enter a coupon code before applying";
        }),
      );
      return;
    }
    dispatch(startCommonLoading());
    try {
      const apiData = await applyCouponToCart({ couponCode: coupon.code });
      const data = handleGraphqlResponse(apiData);
      const products = data?.applyCouponToCart?.cart?.itemsV2?.items;
      const productsMap = getProductsMap(products);
      const _cartPrice = data?.applyCouponToCart?.cart?.prices;
      const _appliedCoupons = data?.applyCouponToCart?.cart?.applied_coupons;
      const uniq_Count = data?.applyCouponToCart?.cart?.itemsV2?.total_count;
      const total_Count = data?.applyCouponToCart?.cart?.total_quantity;
      const isHomeDeliveryAvailable =
        data?.applyCouponToCart?.cart?.is_home_delivery_available;
      dispatch(addOrUpdateProducts(productsMap));
      dispatch(addOrUpdateCartPrice(_cartPrice));
      dispatch(addOrUpdateAppliedCoupons(_appliedCoupons));
      dispatch(updateTotalCount(total_Count));
      dispatch(updateUniqCount(uniq_Count));
      dispatch(updateIsHomedeliveryAvailable(isHomeDeliveryAvailable));
      Cookies.set("specialCoupon", coupon.code, {
        secure: true,
        sameSite: "strict",
      });
      setCoupon({ code: "", error: "" });
      handleToastSuccess("Coupon applied successfully.");
      onSuccess?.();
    } catch (error) {
      handleToastError(error.message);
    }
    dispatch(endCommonLoading());
  };

  const handleRemoveCouponCode = async ({ onSuccess } = {}) => {
    dispatch(startCommonLoading());
    try {
      const apiData = await removeCouponFromCart();
      const data = handleGraphqlResponse(apiData);
      const products = data?.removeCouponFromCart?.cart?.itemsV2?.items;
      const productsMap = getProductsMap(products);
      const uniq_Count = data?.removeCouponFromCart?.cart?.itemsV2?.total_count;
      const total_Count = data?.removeCouponFromCart?.cart?.total_quantity;
      const _cartPrice = data?.removeCouponFromCart?.cart?.prices;
      const _appliedCoupons = data?.removeCouponFromCart?.cart?.applied_coupons;
      const isHomeDeliveryAvailable =
        data?.removeCouponFromCart?.cart?.is_home_delivery_available;
      dispatch(addOrUpdateCartPrice(_cartPrice));
      dispatch(addOrUpdateAppliedCoupons(_appliedCoupons));
      dispatch(addOrUpdateProducts(productsMap));
      dispatch(updateTotalCount(total_Count));
      dispatch(updateUniqCount(uniq_Count));
      dispatch(updateIsHomedeliveryAvailable(isHomeDeliveryAvailable));
      handleToastSuccess("Coupon removed successfully.");
      Cookies.remove("specialCoupon");
      setCoupon({ code: "", error: "" });
      onSuccess?.();
    } catch (error) {
      handleToastError(error.message);
    }
    dispatch(endCommonLoading());
  };

  const firstCoupon = appliedCoupons?.[0]?.code || appliedCoupons;

  const handleAutoApplyCouponCode = async ({ onSuccess } = {}) => {
    if (!coupon.code && !specialCoupon) {
      setCoupon(
        produce((prevState) => {
          prevState.error = "Please enter coupon code.";
        }),
      );
      return;
    }
    dispatch(startCommonLoading());
    try {
      const apiData = await applyCouponToCart({ couponCode: specialCoupon });
      const data = handleGraphqlResponse(apiData);
      const products = data?.applyCouponToCart?.cart?.itemsV2?.items;
      const productsMap = getProductsMap(products);
      const _cartPrice = data?.applyCouponToCart?.cart?.prices;
      const _appliedCoupons = data?.applyCouponToCart?.cart?.applied_coupons;
      const uniq_Count = data?.applyCouponToCart?.cart?.itemsV2?.total_count;
      const total_Count = data?.applyCouponToCart?.cart?.total_quantity;
      const isHomeDeliveryAvailable =
        data?.applyCouponToCart?.cart?.is_home_delivery_available;
      dispatch(addOrUpdateProducts(productsMap));
      dispatch(addOrUpdateCartPrice(_cartPrice));
      dispatch(addOrUpdateAppliedCoupons(_appliedCoupons));
      dispatch(updateTotalCount(total_Count));
      dispatch(updateUniqCount(uniq_Count));
      dispatch(updateIsHomedeliveryAvailable(isHomeDeliveryAvailable));
      onSuccess?.();
    } catch (error) {}
    dispatch(endCommonLoading());
  };

  const RemoveModal = () => {
    return (
      <Modal
        open={removeModal}
        onSubmit={onConfirmRemove}
        onCancel={onCancelRemove}
        title="Remove?"
        description="Do you want to remove this product?"
        confirmBtn="Yes, I do."
        cancelBtn="Cancel"
      />
    );
  };
  return {
    data: {
      cartId,
      cartPrice,
      cartProducts,
      uniqCount,
      totalCount,
      productsState,
      setProductsState,
      removeModal,
      coupon,
      appliedCoupons,
      firstCoupon,
      isHomeDeleiveryAvailable,
      isCnCAvailable,
      alerts,
    },
    methods: {
      handleAddToTrolley,
      handleRemoveFromCart,
      handleQuantityInput,
      handleEnterOnInput,
      handlePlus,
      handleMinus,
      onRemove,
      onConfirmRemove,
      onCancelRemove,
      handleCouponCodeChange,
      handleApplyCouponCode,
      handleRemoveCouponCode,
      handleRemoveOutStockProductsFromCart,
      handleAutoApplyCouponCode,
    },
    components: {
      RemoveModal,
    },
  };
};

export default useTrolleyUtility;
